import {AppBar, Button, Drawer, IconButton, TextField, Toolbar, Typography} from "@mui/material";
import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import {actionButtonColor, standardAppBarColor} from "../style/Colors";
import {ThemeProvider} from "@mui/material/styles";
import {darkTheme} from "../style/Themes";
import CustomDrawer from "./CustomDrawer";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from "react-router-dom";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {LabelService} from "../utils/LabelService";
import {LocalStorageService} from "../utils/LocalStorageService";
import {RequestService} from "../utils/RequestService";
import {EndpointService} from "../utils/EndpointService";

interface proptype{
	title : string;
	showHome: boolean;
	zpl_code: string;
}


function ViewLabelAppBar({ title, showHome, zpl_code}  : proptype){

	const navigateTo = useNavigate();

	const [open, setOpen] = React.useState(false);

	const [ipAddress, setIpAddress] = React.useState(LocalStorageService.get_printer_ip());
	const [quantity, setQuantity] = React.useState('1');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handlePrint = () => {

		let payload = {
			ip_address: ipAddress,
			zpl_code:zpl_code.replace("PQ1", "PQ"+quantity),
		}
		
		// @ts-ignore
		RequestService.send_auth_post_request(
			EndpointService.get_label_endpoint('print'),
			payload
		)
			.then(response => {
				// 	Check here the response
				LocalStorageService.set_printer_ip(ipAddress ? ipAddress : "")
				handleClose()
			})
			.catch(error => {
				// 	Handle here the error
			})
	};

	function handleHomeClick(){
		document.location.href="/";
	}

	return (
		<>
		<AppBar position="static" sx={{backgroundColor: standardAppBarColor}}>
			<Toolbar>
				{showHome &&
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					sx={{ mr: 2 }}
					onClick={handleHomeClick}
				>
					<HomeIcon />
				</IconButton>
				}
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					{title}
				</Typography>
				<Button
					sx={{color: actionButtonColor}}
					onClick={handleClickOpen}
				>
					<b>Stampa</b>
				</Button>
			</Toolbar>
		</AppBar>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Stampa Etichetta</DialogTitle>
				<DialogContent>
					<DialogContentText>
						PROTOTIPO: inserire IP stampante
						ex. 192.168.1.5
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="ip"
						label="Indirizzo IP"
						type="email"
						fullWidth
						variant="standard"
						value={ipAddress}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setIpAddress(event.target.value);
						}}
					/>
					<TextField
						autoFocus
						margin="dense"
						id="quantity"
						label="Numero di copie"
						type="number"
						fullWidth
						variant="standard"
						value={quantity}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setQuantity(/^([1-9]\d*|)$/.test(event.target.value)
								? event.target.value
								: quantity);
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Annulla</Button>
					<Button onClick={handlePrint}>Stampa</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default ViewLabelAppBar;