import Box from "@mui/material/Box";
import {Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import * as React from "react";
import Typography from "@mui/material/Typography";
import HomeIcon from '@mui/icons-material/Home';
import PrintIcon from '@mui/icons-material/Print';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {useNavigate} from "react-router-dom";
import {LocalStorageService} from "../utils/LocalStorageService";

interface proptype{
    title : string;
    anchor: string;
    toggleDrawer: any;
}

// function CustomDrawer = (anchor: string, toggleDrawer: any, title: string) => (
function CustomDrawer(props : proptype){

    const navigateTo = useNavigate();

    const { title, anchor, toggleDrawer } = props;

    const handleClick = (event: any) => {

        let pressed = event.target.textContent;
        let target_page = 'bookings';
        switch (pressed) {
            case 'Home':
                navigateTo('/landing')
                break;
            case 'Stampanti':
                // navigateTo('/printers')
                break;
            case 'Gestisci Utenti':
                // navigateTo('/users')
                break;
            case 'Logout':
                LocalStorageService.remove_access_token()
                navigateTo('/')
                break;
        }

    };

    return (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <Typography variant="h6" component="div" sx={{ p: 2, pb: 0 }}>
                {title}
            </Typography>

            <List>
                <ListItem key={'Home'} disablePadding onClick={handleClick}>
                    <ListItemButton>
                        <ListItemIcon>
                           <HomeIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'Home'}/>
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Etichette'} disablePadding onClick={handleClick}>
                    <ListItemButton>
                        <ListItemIcon>
                            <ReceiptIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'Etichette'} />
                    </ListItemButton>
                </ListItem>


                <ListItem key={'Stampanti'} disablePadding onClick={handleClick}>
                    <ListItemButton>
                        <ListItemIcon>
                            <PrintIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'Stampanti'} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Gestisci Utenti'} disablePadding onClick={handleClick}>
                    <ListItemButton>
                        <ListItemIcon>
                            <GroupIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'Gestisci Utenti'} />
                    </ListItemButton>
                </ListItem>

            </List>

            <Divider />

            <List>

                <ListItem key={'NOME UTENTE'} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <PersonIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'NOME UTENTE'} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Logout'} disablePadding onClick={handleClick}>
                    <ListItemButton>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'Logout'} />
                    </ListItemButton>
                </ListItem>

            </List>
        </Box>
    );

}

export default CustomDrawer;