import React, { useEffect, useState } from 'react';
import SimpleAddModal from '../components/SimpleAddModal';
import {Box, GlobalStyles, Typography} from '@mui/material';
import BottomBarComponent from '../components/BottomBarComponent';
import StandardAppBar from "../components/StandardAppBar";
import Container from "@mui/material/Container";
import {RequestService} from "../utils/RequestService";
import {EndpointService} from "../utils/EndpointService";
import {LocalStorageService} from "../utils/LocalStorageService";
import {grayBackgroundColor, standardTextColor} from "../style/Colors";
import {useParams} from "react-router-dom";
import ListItemComponent from "../components/ListItemComponent";
import SimpleEditModal from "../components/SimpleEditModal";

interface groupDataType {
	id: string,
	name: string,
}

interface sectorDataType {
	sector_id: string,
	sector_name: string,
	zone_id: string,
	zone_name: string,
}

function SelectedSectorPage() {

	let { sector_id } = useParams();

	const [groups, setGroups] = useState<groupDataType[]>([]);
	const [openModal, setOpenModal] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [groupToEdit, setGroupToEdit] = useState<groupDataType>({id:'', name:''});

	const [sector, setSector] = useState<sectorDataType>({sector_id:'', sector_name:'', zone_id:'', zone_name:''});

	useEffect(() => {

		RequestService.send_auth_post_request(
			EndpointService.get_group_endpoint('list'),
			{sector_id: sector_id}
		)
			.then(response => {
				setGroups(response.data.groups);
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
				setGroups([]);
			})

		RequestService.send_auth_post_request(
			EndpointService.get_sector_endpoint('info'),
			{sector_id: sector_id}
		)
			.then(response => {
				setSector(response.data);
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})

	}, [])

	function handleAddButton() {
		setOpenModal(true)
	}

	function handleEditButton(id: string, name: string) {
		setGroupToEdit({ id: id, name: name})
		setOpenEditModal(true)
	}

	function handleModalConfirm(name: string, id?: string) {
		RequestService.send_auth_post_request(
			EndpointService.get_group_endpoint(''),
			{name: name, sector_id: sector_id}
		)
			.then(response => {
				window.location.reload()
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})
	}

	function handleEditModalConfirm(name: string, id: string){
		RequestService.send_auth_post_request(
			EndpointService.get_group_endpoint('update'),
			{id: id, name: name}
		)
			.then(response => {
				window.location.reload()
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})
	}

	return (

		<>
			<GlobalStyles
				styles={{
					body: { backgroundColor: grayBackgroundColor }
				}}
			/>

			{sector.zone_id === '' ?
				<StandardAppBar  title='' showDrawer={false} showBackArrow={false} backRoute=''/> :
				<StandardAppBar  title={sector.sector_name} showDrawer={false} showBackArrow={true} backRoute={'/zone/'+sector.zone_id} show_add_button={true} add_button_action={handleAddButton}/>
			}

			<Container component="main" maxWidth="md">

				<Typography variant="h6" component="div" sx={{mt: 3, mb:2, color: standardTextColor}}>
					Seleziona un Gruppo
				</Typography>


				{groups.map(group => (
						<Box key={group.id}>
							<ListItemComponent id={group.id}  title={group.name} route={'/group/'+group.id} show_options={true} edit_button_action={handleEditButton} />
						</Box>
					))
				}

				{/*To prevent that last items in the list are covered*/}
				<Typography align="left" variant="h5" component="div" sx={{mb: 12}}>
				</Typography>


			</Container>

			<BottomBarComponent />

			<SimpleAddModal
				OpenModal={setOpenModal} ModalState={openModal} Title='Aggiungi un Gruppo' onConfirm={handleModalConfirm}
				ErrorText={"Devi inserire il nome del nuovo Gruppo"}
			/>

			<SimpleEditModal
				openModal={setOpenEditModal} modalState={openEditModal} title='Modifica il gruppo' onConfirm={handleEditModalConfirm}
				errorText={"Devi inserire il nuovo nome del gruppo"}
				id={groupToEdit.id} initialValue={groupToEdit.name}/>
		</>
	);
}

export default SelectedSectorPage;
