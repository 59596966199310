export const LocalStorageService = {

    get_access_token: function() {
        if (localStorage.getItem('access_token')){
            return localStorage.getItem('access_token');
        }
        else {
            return false;
        }
    },

    set_access_token: function(value: string){
        localStorage.setItem('access_token', value)
    },

    get_refresh_token: function() {
        if (localStorage.getItem('refresh_token')){
            return localStorage.getItem('refresh_token')
        }
    },

    set_refresh_token: function(value: string){
        localStorage.setItem('refresh_token', value)
    },

    remove_access_token: function() {
        localStorage.removeItem('access_token')
    },

    get_printer_ip: function() {
        if (localStorage.getItem('printer_ip')){
            return localStorage.getItem('printer_ip')
        }
        return ""
    },

    set_printer_ip: function(value: string) {
        localStorage.setItem('printer_ip', value)
    },

};