import React, { useEffect, useState } from 'react';
import SimpleAddModal from '../components/SimpleAddModal';
import {Box, GlobalStyles, Typography} from '@mui/material';
import BottomBarComponent from '../components/BottomBarComponent';
import StandardAppBar from "../components/StandardAppBar";
import Container from "@mui/material/Container";
import {RequestService} from "../utils/RequestService";
import {EndpointService} from "../utils/EndpointService";
import {LocalStorageService} from "../utils/LocalStorageService";
import {grayBackgroundColor, standardTextColor} from "../style/Colors";
import {useParams} from "react-router-dom";
import ListItemComponent from "../components/ListItemComponent";
import AddProductDialog from "../components/AddProductDialog";

interface productDataType {
	id: string,
	name: string,
}

interface groupDataType {
	sector_id: string,
	sector_name: string,
	group_id: string,
	group_name: string,
}


function SelectedGroupPage() {

	let { group_id } = useParams();

	const [products, setProducts] = useState<productDataType[]>([]);
	const [openDialog, setOpenDialog] = useState(false);

	const [group, setGroup] = useState<groupDataType>({sector_id:'', sector_name:'', group_id:'', group_name:''});

	useEffect(() => {

		RequestService.send_auth_post_request(
			EndpointService.get_product_endpoint('list'),
			{group_id: group_id}
		)
			.then(response => {
				setProducts(response.data.products);
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
				setProducts([]);
			})

		RequestService.send_auth_post_request(
			EndpointService.get_group_endpoint('info'),
			{group_id: group_id}
		)
			.then(response => {
				setGroup(response.data);
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})

	}, [])

	function handleAddButton() {
		setOpenDialog(true)
	}

	return (
		<>
			<GlobalStyles
				styles={{
					body: { backgroundColor: grayBackgroundColor }
				}}
			/>


			{group.group_id === '' ?
				<StandardAppBar  title='' showDrawer={false} showBackArrow={false} backRoute=''/> :
				<StandardAppBar  title={group.group_name} showDrawer={false} showBackArrow={true} backRoute={'/sector/'+group.sector_id} show_add_button={true} add_button_action={handleAddButton}/>
			}

			<Container component="main" maxWidth="md">

				<Typography variant="h6" component="div" sx={{mt: 3, mb:2, color: standardTextColor}}>
					Seleziona un Prodotto
				</Typography>


				{products.map(product => (
						<Box key={product.id}>
							<ListItemComponent id={product.id} title={product.name} route={'/product/'+product.id} />
						</Box>
					))
				}

				{/*To prevent that last items in the list are covered*/}
				<Typography align="left" variant="h5" component="div" sx={{mb: 12}}>
				</Typography>


			</Container>

			<BottomBarComponent />

			{openDialog &&
				// @ts-ignore
				<AddProductDialog  open={openDialog} setDialogOpen={setOpenDialog} groupId={group_id}/>
			}
		</>
	);
}

export default SelectedGroupPage;
