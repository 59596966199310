import {AppBar, Drawer, IconButton, Toolbar, Typography} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from "@mui/icons-material/Menu";
import {actionButtonColor, standardAppBarColor} from "../style/Colors";
import {ThemeProvider} from "@mui/material/styles";
import {darkTheme} from "../style/Themes";
import CustomDrawer from "./CustomDrawer";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from "react-router-dom";

interface proptype{
	title : string;
	showDrawer: boolean;
	showBackArrow: boolean;
	backRoute?: string;
	show_add_button?: boolean;
	add_button_action?: Function;
}


function StandardAppBar({ title, showDrawer, showBackArrow, backRoute='', show_add_button=false, add_button_action= () => void 0}  : proptype){

	const navigateTo = useNavigate();

	const [drawerState, setDrawerState] = React.useState(false);

	const toggleDrawer = (open: boolean | ((prevState: boolean) => boolean)) => (event: any) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setDrawerState(open);
	};

	function handleBackClick(){
		navigateTo(backRoute)
		console.log(backRoute)
	}

	const handleAddClick = () => {
		add_button_action();
	}

	return (
		<>
		<AppBar position="static" sx={{backgroundColor: standardAppBarColor}}>
			<Toolbar>
				{showDrawer &&
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					sx={{ mr: 2, color: actionButtonColor}}
					onClick={toggleDrawer(true)}
				>
					<MenuIcon />
				</IconButton>
				}
				{showBackArrow &&
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ mr: 2, color: actionButtonColor}}
						onClick={handleBackClick}
					>
						<ArrowBackIcon />
					</IconButton>
				}
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					{title}
				</Typography>
				{show_add_button &&
				<IconButton
					size="large"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					sx={{color: actionButtonColor}}
					onClick={handleAddClick}
				>
					<AddIcon/>
				</IconButton>
				}
			</Toolbar>
		</AppBar>
			{showDrawer &&
			<ThemeProvider theme={darkTheme}>
				<React.Fragment key={'drawer'}>
					<Drawer
						anchor={'left'}
						open={drawerState}
						onClose={toggleDrawer(false)}
					>
						<CustomDrawer title={'Food Tracking'} anchor={'left'} toggleDrawer={toggleDrawer} />
					</Drawer>
				</React.Fragment>
			</ThemeProvider>
			}
		</>
	);
}

export default StandardAppBar;