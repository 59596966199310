import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {standardAppBarColor} from "../style/Colors";
import {useNavigate} from "react-router-dom";

interface proptype{
	backRoute: string;
}


function StandardAppBar(props : proptype){
	const { backRoute } = props;

	const navigateTo = useNavigate();

	function handleBackClick(){
		navigateTo(backRoute)
		console.log(backRoute)
	}

	return (
		<AppBar position="static" sx={{backgroundColor: standardAppBarColor}}>
			<Toolbar>
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					sx={{ mr: 2 }}
					onClick={handleBackClick}
				>
					<ArrowBackIcon />
				</IconButton>

				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					Crea Etichetta
				</Typography>

			</Toolbar>
		</AppBar>
	);
}

export default StandardAppBar;