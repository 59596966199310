import axios from 'axios';
import {LocalStorageService} from "./LocalStorageService";
import { Buffer } from "buffer";

export const RequestService = {

    send_post_request: function (path: string, payload = {}){
        return axios.post(path, payload)
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    throw new Error('Invalid authorization code');
                }
            })
            .catch(error => {
                throw new Error('Invalid authorization code');
            });
    },

    send_auth_post_request: function(path: string, payload = {}) {
        axios.interceptors.request.use(
            config => {
                const token = LocalStorageService.get_access_token();
                config.headers.authorization = `${token}`;
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        return this.send_post_request(path, payload)

    },

    send_get_request: function (path: string, queryParams = {}){
        const params = new URLSearchParams(queryParams);
        return axios.get(path + (Object.keys(queryParams).length===0 ? '' : '/?') + params)
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    throw new Error('Invalid authorization code');
                }
            })
            .catch(error => {
                throw new Error('Invalid authorization code');
            });
    },

    send_auth_get_request: function(path: string, payload = {}) {
        axios.interceptors.request.use(
            config => {
                const token = LocalStorageService.get_access_token();
                config.headers.authorization = `${token}`;
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        return this.send_get_request(path, payload)

    },

    get_image_url: async function (path: string, payload = {}) {
        axios.interceptors.request.use(
            config => {
                const token = LocalStorageService.get_access_token();
                config.headers.authorization = `${token}`;
                config.responseType = "arraybuffer"
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        try {
            const response = await this.send_get_request(path, payload)

            // Convert the response data to a base64-encoded URL
            const base64Image = Buffer.from(response.data, 'binary').toString('base64');
            return `data:image/jpeg;base64,${base64Image}`;
        } catch (error) {
            console.error('Error fetching image:', error);
        }


    },

    send_auth_patch_request: function(path: string, payload = {}) {
        axios.interceptors.request.use(
            config => {
                const token = LocalStorageService.get_access_token();
                config.headers.authorization = `${token}`;
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        return axios.patch(path, payload)
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    throw new Error('Invalid authorization code');
                }
            })
            .catch(error => {
                throw new Error('Invalid authorization code');
            });

    },

};