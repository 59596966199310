import {Navigate} from "react-router-dom";
import {BECheckService} from "./BECheckService";
import {useEffect, useState} from "react";
import {LocalStorageService} from "./LocalStorageService";

// @ts-ignore
export const PublicRoute = ({children}) => {

    const [is_authorized, set_is_authorized] = useState(false);
    const [is_token_evaluated, set_is_token_evaluated] = useState(false);

    useEffect(() => {

        if (LocalStorageService.get_access_token()) {
            BECheckService.is_token_valid()
                .then(response => {
                    set_is_authorized(response.status === 200);
                })
                .catch(error => {
                    set_is_authorized(false);
                })
                .then(() => set_is_token_evaluated(true));
        } else {
            set_is_token_evaluated(true); // in case there is no token
        }

    }, [])

    if (!is_token_evaluated) return <div />; // or some kind of loading animation

    return is_authorized ? <Navigate to="/landing"/> : children;
}