import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useRef, useState } from 'react';
import { TextField } from '@mui/material';

interface ModalProps{
	id : string,
	title : string,
	modalState: any,
	openModal : any,
	onConfirm: Function,

}
function SimpleDeleteModal(props : ModalProps) {

	
	const handleClose = () => {
		props.openModal(false);
	};

	const style = {
		position: 'absolute' as 'absolute',
		top: '40%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		
	};

	const onConfirm = () => {
		props.onConfirm(props.id);
		handleClose();
	}

	return (
		<>
			
				
				<Modal
					open={props.modalState}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style} className='w-11/12 max-w-[400px] px-8 pt-8 pb-4'>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							{props.title}
						</Typography>

						<Box component={"div"} className='flex justify-end gap-3 mt-4'>

						<Button size="large" color='success' className='!p-0' onClick={()=>{handleClose()}}>Annulla</Button>
						<Button size="large" color='error' className='!p-0' onClick={onConfirm}>ELIMINA</Button>
						</Box>
					</Box>
				</Modal>
			
		</>
	)
}
export default SimpleDeleteModal;