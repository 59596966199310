import {RequestService} from "./RequestService";
import {EndpointService} from "./EndpointService";
import {LocalStorageService} from "./LocalStorageService";
import axios from "axios";


// @ts-ignore
String.prototype.format = function() {
    let formatted = this;
    for (let i = 0; i < arguments.length; i++) {
        let regexp = new RegExp('\\{'+i+'\\}', 'gi');
        formatted = formatted.replace(regexp, arguments[i]);
    }
    return formatted;
};

const TEMPLATE_1 = "^XA\n" +
    "^PW439\n" +
    "^LL280\n" +
    "^LS0\n" +
    "\n" +
    "^FO30,10\n" +
    "^BQN,2,5\n" +
    "^FDMA,{0}^FS\n" +
    "\n" +
    "^FO220,20\n" +
    "^A0N,20,20\n" +
    "^FDData di produzione^FS\n" +
    "\n" +
    "^FO220,50\n" +
    "^A@N,25,15\n" +
    "^FD{1}^FS\n" +
    "\n" +
    "^FO220,90\n" +
    "^A0N,20,20\n" +
    "^FDData di scadenza^FS\n" +
    "\n" +
    "^FO220,120\n" +
    "^A@N,25,15\n" +
    "^FD{2}^FS\n" +
    "\n" +
    "^FO30,200\n" +
    "^A0N,30,30\n" +
    "^FD{3}^FS\n" +
    "\n" +
    // "^FO30,188\n" +
    // "^A0N,20,20\n" +
    // "^FD{4}^FS\n" +
    // "\n" +
    // "^FO30,212\n" +
    // "^A0N,20,20\n" +
    // "^FD{5}^FS\n" +
    "\n" +
    "^FO30,242\n" +
    "^A@N,15,10\n" +
    "^FDID: {6}^FS\n" +
    "\n" +
    "^PQ1\n" +
    "\n" +
    "^XZ"

const PREVIEW_URL_TEMPLATE = "http://api.labelary.com/v1/printers/8dpmm/labels/2.16x1.37/0/{0}"
export const LabelService = {
    // @ts-ignore
    get_zpl_code: function (url, data_scadenza, data_produzione, nome_prodotto, azienda, operatore, id_etichetta) {
        // @ts-ignore
        return TEMPLATE_1.format(url, data_scadenza, data_produzione, nome_prodotto, azienda, operatore, id_etichetta)
    },
    get_preview_url: function (zpl_code: string) {
        // @ts-ignore
        return PREVIEW_URL_TEMPLATE.format(zpl_code)
    },


};