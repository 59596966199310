import React, {useState} from "react";
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Dialog from "@mui/material/Dialog";
import AddProductAppBar from "./AddProductDialogAppBar";
import {Button, FormControl, Grid, InputLabel, Select, TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {actionButtonColor, standardTextColor} from "../style/Colors";
import Container from "@mui/material/Container";
import SaveIcon from "@mui/icons-material/Save";
import {RequestService} from "../utils/RequestService";
import {EndpointService} from "../utils/EndpointService";
import {LocalStorageService} from "../utils/LocalStorageService";

interface proptype{
    open: boolean;
    setDialogOpen: Function;
    groupId: string
}


function AddProductDialog(props : proptype){

    const handleClose = () => {
        props.setDialogOpen(false);
    };

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const handleSubmit = (event: { preventDefault: () => void; currentTarget: HTMLFormElement | undefined; }) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let days = 0
        if(data.get('days') && data.get('days') !== ''){
            days = parseInt(data.get('days') as string)
        }

        let daysFrozen = 0
        if(data.get('daysFrozen') && data.get('daysFrozen') !== ''){
            daysFrozen = parseInt(data.get('daysFrozen') as string)
        }

        let payload = {
            name: data.get('name'),
            group_id: props.groupId,
            days:days,
            days_frozen: daysFrozen
        }

        RequestService.send_auth_post_request(
            EndpointService.get_product_endpoint(''),
            payload
        )
            .then((response: any) => {
                if (response.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error: any) => {

            });
    }


    return(
        <Dialog
            fullScreen
            open={props.open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AddProductAppBar title={"Aggiungi un Prodotto"} close_action={handleClose} save_action={() => 0}/>

            <Container component="main" maxWidth="md">
            <form onSubmit={handleSubmit}>

                <Grid container spacing={3} sx={{mt:2, mb:1}}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="name"
                            label="Nome Prodotto"
                            name="name"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="days"
                            label="Durata in giorni"
                            type="number"
                            name="days"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="daysFrozen"
                            label="Durata in giorni (surgelato)"
                            type="number"
                            name="daysFrozen"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            endIcon={<SaveIcon/>}
                            type="submit"
                            sx={{backgroundColor:actionButtonColor, color:standardTextColor,
                                ":hover": {
                                    bgcolor: actionButtonColor,
                                    color: standardTextColor
                                },
                            }}
                        >
                            Salva
                        </Button>
                    </Grid>
                </Grid>

            </form>
            </Container>

        </Dialog>
    );
}
export default AddProductDialog;