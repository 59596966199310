import React, { useEffect, useState } from 'react';
import CustomCard from '../components/CustomCard';
import SimpleAddModal from '../components/SimpleAddModal';
import {Box, GlobalStyles, Typography} from '@mui/material';
import BottomBarComponent from '../components/BottomBarComponent';
import StandardAppBar from "../components/StandardAppBar";
import Container from "@mui/material/Container";
import {RequestService} from "../utils/RequestService";
import {EndpointService} from "../utils/EndpointService";
import {LocalStorageService} from "../utils/LocalStorageService";
import {grayBackgroundColor, standardTextColor} from "../style/Colors";
import ListItemComponent from "../components/ListItemComponent";
import SimpleEditModal from "../components/SimpleEditModal";
import SimpleDeleteModal from "../components/SimpleDeleteModal";

interface zonaDataType {
	id: string,
	name: string,
}

function LandingPage() {

	const [zones, setZones] = useState<zonaDataType[]>([]);
	const [openModal, setOpenModal] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const [zoneToEdit, setZoneToEdit] = useState<zonaDataType>({id:'', name:''});
	const [zoneToDelete, setZoneToDelete] = useState<zonaDataType>({id:'', name:''});

	useEffect(() => {

		RequestService.send_auth_get_request(
			EndpointService.get_zone_endpoint('list')
		)
			.then(response => {
				setZones(response.data.zones);
				console.log(response.data.zones)
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
				setZones([]);
			})

	}, [])

	function handleModalConfirm(name: string, id?: string) {
		RequestService.send_auth_post_request(
			EndpointService.get_zone_endpoint(''),
			{name: name}
		)
			.then(response => {
				window.location.reload()
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})
	}

	function handleAddButton() {
		setOpenModal(true)
	}

	function handleEditModalConfirm(name: string, id: string){
		RequestService.send_auth_post_request(
			EndpointService.get_zone_endpoint('update'),
			{id: id, name: name}
		)
			.then(response => {
				window.location.reload()
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})
	}

	function handleDeleteModalConfirm(id: string){
		RequestService.send_auth_post_request(
			EndpointService.get_zone_endpoint('delete'),
			{id: id}
		)
			.then(response => {
				window.location.reload()
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})
	}

	function handleEditButton(id: string, name: string) {
		setZoneToEdit({ id: id, name: name})
		setOpenEditModal(true)
	}

	function handleDeleteButton(id: string, name: string) {
		setZoneToDelete({ id: id, name: name})
		setOpenDeleteModal(true)
	}

	return (
		<>
			<GlobalStyles
				styles={{
					body: { backgroundColor: grayBackgroundColor }
				}}
			/>

			<StandardAppBar  title={'Food Tracking'} showDrawer={true} showBackArrow={false} show_add_button={true} add_button_action={handleAddButton}/>

			<Container component="main" maxWidth="md">

				<Typography variant="h6" component="div" sx={{mt: 3, mb:2, color: standardTextColor}}>
					Seleziona una Zona
				</Typography>


				{zones.map(zona => (
						<Box key={zona.id}>
							<ListItemComponent id={zona.id} title={zona.name} route={'/zone/'+zona.id} show_options={true} edit_button_action={handleEditButton} delete_button_action={handleDeleteButton} />
						</Box>
					))
				}


			</Container>

			<BottomBarComponent />

			<SimpleAddModal
				OpenModal={setOpenModal} ModalState={openModal} Title='Aggiungi una zona' onConfirm={handleModalConfirm}
				ErrorText={"Devi inserire il nome della nuova Zona"}
			/>

			<SimpleEditModal
				openModal={setOpenEditModal} modalState={openEditModal} title='Modifica la zona' onConfirm={handleEditModalConfirm}
				errorText={"Devi inserire il nuovo nome della Zona"}
			 id={zoneToEdit.id} initialValue={zoneToEdit.name}/>

			<SimpleDeleteModal
				openModal={setOpenDeleteModal} modalState={openDeleteModal} title={'Vuoi eliminare '+zoneToDelete.name} onConfirm={handleDeleteModalConfirm}
				id={zoneToDelete.id} />
		</>
	);
}

export default LandingPage;
