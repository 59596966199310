import React, { useEffect, useState } from 'react';
import SimpleAddModal from '../components/SimpleAddModal';
import {Box, GlobalStyles, Typography} from '@mui/material';
import BottomBarComponent from '../components/BottomBarComponent';
import StandardAppBar from "../components/StandardAppBar";
import Container from "@mui/material/Container";
import {RequestService} from "../utils/RequestService";
import {EndpointService} from "../utils/EndpointService";
import {LocalStorageService} from "../utils/LocalStorageService";
import {grayBackgroundColor, standardTextColor} from "../style/Colors";
import {useParams} from "react-router-dom";
import ListItemComponent from "../components/ListItemComponent";
import SimpleEditModal from "../components/SimpleEditModal";

interface sectorDataType {
	id: string,
	name: string,
}

function SelectedZonePage() {

	let { zone_id } = useParams();

	const [sectors, setSectors] = useState<sectorDataType[]>([]);
	const [openModal, setOpenModal] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [sectorToEdit, setSectorToEdit] = useState<sectorDataType>({id:'', name:''});

	const [zoneName, setZoneName] = useState('NO_NAME');

	useEffect(() => {

		RequestService.send_auth_post_request(
			EndpointService.get_sector_endpoint('list'),
			{zone_id: zone_id}
		)
			.then(response => {
				setSectors(response.data.sectors);
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
				setSectors([]);
			})

		RequestService.send_auth_post_request(
			EndpointService.get_zone_endpoint('info'),
			{zone_id: zone_id}
		)
			.then(response => {
				setZoneName(response.data.zone_name);
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})

	}, [])

	function handleAddButton() {
		setOpenModal(true)
	}

	function handleEditButton(id: string, name: string) {
		setSectorToEdit({ id: id, name: name})
		setOpenEditModal(true)
	}

	function handleModalConfirm(name: string) {
		RequestService.send_auth_post_request(
			EndpointService.get_sector_endpoint(''),
			{name: name, zone_id: zone_id}
		)
			.then(response => {
				window.location.reload()
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})
	}

	function handleEditModalConfirm(name: string, id: string){
		RequestService.send_auth_post_request(
			EndpointService.get_sector_endpoint('update'),
			{id: id, name: name}
		)
			.then(response => {
				window.location.reload()
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})
	}

	return (
		<>
			<GlobalStyles
				styles={{
					body: { backgroundColor: grayBackgroundColor }
				}}
			/>

			{zoneName === 'NO_NAME' ?
				<StandardAppBar  title='' showDrawer={false} showBackArrow={false} backRoute=''/> :
				<StandardAppBar  title={''+zoneName} showDrawer={false} showBackArrow={true} backRoute={'/'} show_add_button={true} add_button_action={handleAddButton}/>
			}

			<Container component="main" maxWidth="md">

				<Typography variant="h6" component="div" sx={{mt: 3, mb:2, color: standardTextColor}}>
					Seleziona un Settore
				</Typography>


				{sectors.map(sector => (
						<Box key={sector.id}>
							<ListItemComponent id={sector.id} title={sector.name} route={'/sector/'+sector.id} show_options={true} edit_button_action={handleEditButton} />
						</Box>
					))
				}

				{/*To prevent that last items in the list are covered*/}
				<Typography align="left" variant="h5" component="div" sx={{mb: 12}}>
				</Typography>


			</Container>

			<BottomBarComponent />

			<SimpleAddModal
				OpenModal={setOpenModal} ModalState={openModal} Title='Aggiungi un settore' onConfirm={handleModalConfirm}
				ErrorText={"Devi inserire il nome del nuovo Settore"}
			/>

			<SimpleEditModal
			openModal={setOpenEditModal} modalState={openEditModal} title='Modifica il settore' onConfirm={handleEditModalConfirm}
			errorText={"Devi inserire il nuovo nome del settore"}
			id={sectorToEdit.id} initialValue={sectorToEdit.name}/>
		</>
	);
}

export default SelectedZonePage;
