import {createTheme} from "@mui/material/styles";

const { palette } = createTheme();
const { augmentColor } = palette;

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#009592',
        },
    },
});