import { AppBar, Fab, Toolbar, styled } from "@mui/material"
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import {actionButtonColor, standardAppBarColor} from "../style/Colors";
import {useNavigate} from "react-router-dom";

function BottomBarComponent() {
	const ScanButtonStyle = styled(Fab)({
		position: 'absolute',
		zIndex: 1,
		top: -30,
		left: 0,
		right: 0,
		margin: '0 auto',
		backgroundColor: actionButtonColor
	});

	const navigateTo = useNavigate();
	const navigate = () => {
		navigateTo('/scan');
	}

	return (
		<>
			<AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, backgroundColor: standardAppBarColor}}>
				<Toolbar>
					<ScanButtonStyle className="max-w-[150px]" size="large" variant="extended" aria-label="add" onClick={navigate}>
						<DocumentScannerIcon className="mr-2" /> Scansiona
					</ScanButtonStyle>
				</Toolbar>
			</AppBar>
		</>
	)
}
export default BottomBarComponent