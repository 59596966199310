import {Divider, IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate} from "react-router-dom";
import React from "react";
import {deleteActionButtonColor} from "../style/Colors";

interface listItemProps {
    id: string;
    title: string
    route: string
    show_options?: boolean
    edit_button_action?: Function;
    delete_button_action?: Function;
}


function ListItemComponent({id, title, route, show_options = false, edit_button_action= () => void 0, delete_button_action= () => void 0}: listItemProps) {
    const navigateTo = useNavigate();
    const navigate = () => {
        navigateTo(route);
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        edit_button_action(id, title);
        handleClose();
    };

    const handleDelete = () => {
        delete_button_action(id, title);
        handleClose();
    };

    return (
        <>
            <ListItem className="cursor-pointer" secondaryAction={
				show_options &&
				<IconButton edge="end" aria-label="delete" onClick={handleClick}>
					<MoreVertIcon/>
				</IconButton>
            }>

                <ListItemText onClick={navigate}
                              primaryTypographyProps={{fontSize: 25}}
                              primary={title}
                />

            </ListItem>
            <Divider/>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleEdit}>
					<ListItemIcon>
						<EditIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Modifica</ListItemText>
				</MenuItem>
                <MenuItem onClick={handleDelete}>
					<ListItemIcon>
						<DeleteIcon fontSize="small" sx={{color: deleteActionButtonColor}} />
					</ListItemIcon>
					<ListItemText>Elimina</ListItemText>
				</MenuItem>
            </Menu>
        </>


    )
}

export default ListItemComponent