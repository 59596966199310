import {AppBar, Button, IconButton, Toolbar, Typography} from "@mui/material";
import React from "react";
import {actionButtonColor, standardAppBarColor} from "../style/Colors";
import CloseIcon from '@mui/icons-material/Close';

interface proptype{
    title : string;
    close_action: Function;
    save_action: Function;
}


function AddProductAppBar({ title, close_action, save_action}  : proptype){

    function handleCloseClick(){
        close_action()
    }

    const handleSaveClick = () => {
        save_action();
    }

    return (
        <>
            <AppBar position="static" sx={{backgroundColor: standardAppBarColor}}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        aria-label="menu"
                        sx={{ mr: 2, color: actionButtonColor}}
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    {/*<Button sx={{color: actionButtonColor}} onClick={handleSaveClick}>*/}
                    {/*    <b>Salva</b>*/}
                    {/*</Button>*/}
                </Toolbar>
            </AppBar>
        </>
    );
}

export default AddProductAppBar;