import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	GlobalStyles,
	Grid,
	IconButton,
	ImageList, ImageListItem,
	Typography
} from '@mui/material';
import Container from "@mui/material/Container";
import {RequestService} from "../utils/RequestService";
import {EndpointService} from "../utils/EndpointService";
import {LocalStorageService} from "../utils/LocalStorageService";
import {actionButtonColor, grayBackgroundColor, standardTextColor} from "../style/Colors";
import {useNavigate, useParams} from "react-router-dom";
import StandardAppBar from "../components/StandardAppBar";
import ListItemComponent from "../components/ListItemComponent";
import dayjs from "dayjs";
import ReceiptIcon from '@mui/icons-material/Receipt';


require('dayjs/locale/it')
dayjs.locale('it')

interface productDataType {
	product_id: string,
	product_name: string,
	group_id: string,
	group_name: string,
	days: number,
	days_frozen: number
}

interface labelDataType {
	label_id: string,
	production_date: string,
	n_images: number,
}

function SelectedProductPage() {

	let { product_id } = useParams();

	const [product, setProduct] = useState<productDataType>({product_id: "", product_name: "", group_id: "", group_name: "", days: 0, days_frozen: 0});
	const [labels, setLabels] = useState<labelDataType[]>([]);

	const navigateTo = useNavigate();

	useEffect(() => {

		RequestService.send_auth_post_request(
			EndpointService.get_product_endpoint('info'),
			{product_id: product_id}
		)
			.then(response => {
				setProduct(response.data);
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})

		RequestService.send_auth_get_request(
			EndpointService.get_product_endpoint(product_id+'/labels')
		)
			.then(response => {
				setLabels(response.data);
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})

	}, [])

	function handleAddLabelClick(){
		navigateTo('/product/'+ product.product_id +'/label')
	}

	return (
		<>
			<GlobalStyles
				styles={{
					body: { backgroundColor: grayBackgroundColor }
				}}
			/>

			{product.group_id === '' ?
				<StandardAppBar  title='' showDrawer={false} showBackArrow={false} backRoute=''/> :
				<StandardAppBar  title={product.product_name} showDrawer={false} showBackArrow={true} backRoute={'/group/'+product.group_id} show_add_button={false}/>
			}


			<Container component="main" maxWidth="md">

				<Typography align="left" variant="h6" component="div" sx={{mt: 2, mb: 2}}>
					<b>Dettagli:</b> Durata in giorni: {product.days}, surgelato: {product.days_frozen}
				</Typography>

				<Button
					fullWidth
					component="span"
					variant="contained"
					endIcon={<ReceiptIcon />}
					size="large"
					onClick={handleAddLabelClick}
					sx={{textAlign:"center", height:"100%", minHeight:"100px", backgroundColor:actionButtonColor, color:standardTextColor, ":hover": {
							bgcolor: actionButtonColor,
							color: standardTextColor
						},}}
				>
					<b>NUOVA ETICHETTA</b>
				</Button>


				<Typography align="left" variant="h5" component="div" sx={{mt: 2}}>
					<b>Etichette Presenti</b>
				</Typography>

				{labels.map(label => (
					<Box key={label.label_id}>
						<ListItemComponent id={label.label_id} title={dayjs(label.production_date).format('DD/MM/YYYY') + " - " + label.n_images + " foto"} route={'/label/'+label.label_id} />
					</Box>
				))
				}

				{/*To prevent that last items in the list are covered*/}
				<Typography align="left" variant="h5" component="div" sx={{mb: 2}}>
				</Typography>

			</Container>
		</>
	);
}

export default SelectedProductPage;
