// === Background ===
export const grayBackgroundColor = "#E6EDEF";
export const standardAppBarColor = "#272727";

// === Cards ===
export const cardBackgroundColor = "#F9F9F9";
export const selectedCardBackgroundColor = "#FFFFFF";

// === Text ===

export const standardTextColor = "#272727";

//  === Buttons ===

export const actionButtonColor = "#F7C505";
export const deleteActionButtonColor = "#F03C4F"