import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useRef, useState } from 'react';
import { TextField } from '@mui/material';

interface ModalProps{
	Title : string,
	ModalState: any,
	OpenModal : any,
	onConfirm: Function,
	ErrorText : string

}
function SimpleAddModal(props : ModalProps) {

	
	const handleClose = () => {
		props.OpenModal(false);
		setTextError("")
	};

	const inputName  = useRef<HTMLInputElement>(null);
	const [textError, setTextError] = useState("");

	const style = {
		position: 'absolute' as 'absolute',
		top: '40%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		
	};

	const onConfirm = () => {
		const name = inputName.current?.value
		if(name){
			props.onConfirm(name);
			handleClose();
		}else{
			setTextError(props.ErrorText)
		}
		
		
	}

	return (
		<>
			
				
				<Modal
					open={props.ModalState}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style} className='w-11/12 max-w-[400px] px-8 pt-8 pb-4'>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							{props.Title}
						</Typography>

						<TextField autoFocus helperText={textError} autoComplete='off' inputRef={inputName} id="standard-basic" label="Nome" variant="standard" className='w-full' />

						<Box component={"div"} className='flex justify-end gap-3 mt-4'>
						<Button size="large" color='error' className='!p-0' onClick={()=>{handleClose()}}>Cancella</Button>
						<Button size="large" color='success' className='!p-0' onClick={onConfirm}>Conferma</Button>
						</Box>
					</Box>
				</Modal>
			
		</>
	)
}
export default SimpleAddModal;