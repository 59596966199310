import React from 'react';
import { render } from "react-dom";
import './index.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {PublicRoute} from "./utils/PublicRoute";
import {PrivateRoute} from "./utils/PrivateRoute";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import SelectedZonePage from "./pages/SelectedZonePage";
import SelectedSectorPage from "./pages/SelectedSectorPage";
import SelectedGroupPage from "./pages/SelectedGroupPage";
import CreateLabelPage from "./pages/CreateLabelPage";
import ViewLabelPage from "./pages/ViewLabelPage";
import {ScanPage} from "./pages/ScanPage";
import SelectedProductPage from "./pages/SelectedProductPage";


const root = document.getElementById("root");
render(
  <React.StrictMode>
    <BrowserRouter>
        <Routes>
            <Route
                path="/"
                element={
                    <PublicRoute>
                        <LoginPage />
                    </PublicRoute>
                }
            />
            <Route
                path="/landing"
                element={
                    <PrivateRoute>
                        <LandingPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/zone/:zone_id"
                element={
                    <PrivateRoute>
                        <SelectedZonePage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/sector/:sector_id"
                element={
                    <PrivateRoute>
                        <SelectedSectorPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/group/:group_id"
                element={
                    <PrivateRoute>
                        <SelectedGroupPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/product/:product_id"
                element={
                    <PrivateRoute>
                        <SelectedProductPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/product/:product_id/label"
                element={
                    <PrivateRoute>
                        <CreateLabelPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/label/:label_id"
                element={
                    <PrivateRoute>
                        <ViewLabelPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/scan"
                element={
                    <PrivateRoute>
                        <ScanPage />
                    </PrivateRoute>
                }
            />
        </Routes>
    </BrowserRouter>
  </React.StrictMode>
,root);

