import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	GlobalStyles,
	Grid,
	IconButton,
	ImageList, ImageListItem,
	Typography
} from '@mui/material';
import Container from "@mui/material/Container";
import {RequestService} from "../utils/RequestService";
import {EndpointService} from "../utils/EndpointService";
import {LocalStorageService} from "../utils/LocalStorageService";
import {actionButtonColor, grayBackgroundColor, standardTextColor} from "../style/Colors";
import {useNavigate, useParams} from "react-router-dom";
import LabelCreationAppBar from "../components/CreateLabelAppBar";

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {DateTimeService} from "../utils/DateTimeService";

import SaveIcon from '@mui/icons-material/Save';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import axios from "axios";
import Resizer from 'react-image-file-resizer';

import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

require('dayjs/locale/it')
dayjs.locale('it')


dayjs.extend(utc)
dayjs.extend(tz)

const timeZone = dayjs.tz.guess()

interface productDataType {
	product_id: string,
	product_name: string,
	group_id: string,
	group_name: string,
	days: number,
	days_frozen: number
}

function CreateLabelPage() {

	let { product_id } = useParams();

	const [product, setProduct] = useState<productDataType>({product_id: "", product_name: "", group_id: "", group_name: "", days: 0, days_frozen: 0});
	const [selectedImages, setSelectedImages] = useState<any>([]);
	const [openModal, setOpenModal] = useState(false);
	const [isFrozen, setIsFrozen] = React.useState(false);

	const [productionDate, setProductionDate] = useState(dayjs().tz(timeZone));
	const [expirationDate, setExpirationDate] = useState(dayjs().tz(timeZone));

	const navigateTo = useNavigate();

	useEffect(() => {

		RequestService.send_auth_post_request(
			EndpointService.get_product_endpoint('info'),
			{product_id: product_id}
		)
			.then(response => {
				setProduct(response.data);
				setExpirationDate(productionDate.add(response.data.days, 'day'))
			})
			.catch(error => {
				// TODO improve error handling
				if (error.response) { // status code out of the range of 2xx
					if (error.response.status === 400){
						LocalStorageService.remove_access_token()
					}
				} else if (error.request) { // The request was made but no response was received
					console.log(error.request);
				} else {// Error on setting up the request
					console.log('Error', error.message);
				}
			})

	}, [])

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		console.log("sending...")
		let label_id = "";

		try {

			// 1. Create the label
			const payload = {
				product_id: product.product_id,
				production_date:productionDate,
				expiration_date:expirationDate,
				is_frozen: isFrozen,
				n_images: selectedImages.length
			}

			let resized_images: unknown[] = [];
			for (let i = 0 ; i < selectedImages.length ; i++) {
				resized_images.push(await resizeFile(selectedImages[i]));
			}

			RequestService.send_auth_post_request(
				EndpointService.get_label_endpoint(''),
				payload
			)
				.then(response => {
					label_id = response.data.label_id

					// 2. Save the images

					const formData = new FormData();

					for (let i = 0 ; i < resized_images.length ; i++) {
						// @ts-ignore
						formData.append("images", resized_images[i]);
					}

					axios.post(
						EndpointService.get_label_endpoint(label_id+'/images'),
						formData
					)
						.then(response => {
						if (response.status === 200) {
							navigateTo('/label/'+label_id)
						} else {
							throw new Error('Image not saved');
						}
					})
						.catch(error => {
							throw new Error('Image not saved');
						});

				})
				.catch(error => {
					// TODO improve error handling
					if (error.response) { // status code out of the range of 2xx
						if (error.response.status === 400){
							LocalStorageService.remove_access_token()
						}
					} else if (error.request) { // The request was made but no response was received
						console.log(error.request);
					} else {// Error on setting up the request
						console.log('Error', error.message);
					}
				})





			console.log('Form submitted successfully!');
		} catch (error) {
			console.error('Error submitting form:', error);
		}

	};

	const resizeFile = (file: Blob) => {
		return new Promise((resolve, reject) => {
			Resizer.imageFileResizer(
				file,
				600, // New width (adjust as needed)
				800, // New height (adjust as needed)
				'JPEG', // Output format
				80, // Quality (adjust as needed)
				0, // Rotation
				(uri) => {
					resolve(uri);
				},
				'blob' // Output type: 'base64' or 'blob'
			);
		});
	};


	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsFrozen(event.target.checked);
		if(event.target.checked) {
			setExpirationDate(productionDate.add(product.days_frozen, 'day'))
		}else {
			setExpirationDate(productionDate.add(product.days, 'day'))
		}
	};

	const handleImageChange = (event: any) => {
		const files = Array.from(event.target.files);
		setSelectedImages([...selectedImages, ...files]);
	};

	return (
		<>
			<GlobalStyles
				styles={{
					body: { backgroundColor: grayBackgroundColor }
				}}
			/>

			{product.group_id === '' ?
				<LabelCreationAppBar  backRoute={''}/> :
				<LabelCreationAppBar  backRoute={'/product/'+product.product_id}/>
			}


			<Container component="main" maxWidth="md">

				<Typography align="left" variant="h6" component="div" sx={{mt: 2}}>
					Prodotto: <b>{product.product_name}</b>
				</Typography>


				<form onSubmit={handleSubmit}>

					<Grid container spacing={1} sx={{mt:1, mb:1}}>


						{selectedImages.map((image: Blob | MediaSource, index: React.Key | null | undefined) => (
							<Grid item xs={6}>
								<img
									key={index}
									src={URL.createObjectURL(image)}
									alt={`Selected ${index}`}
									style={{maxWidth: '100%',
										maxHeight: '100%', borderRadius: '5px'}}
								/>
							</Grid>
						))}



						<Grid item xs={6}>
							<input
								type="file"
								accept="image/*"
								capture="environment"
								multiple
								style={{display:'none'}}
								onChange={handleImageChange}
								id="photo-input"
							/>
							<label htmlFor="photo-input">
								<Button
									fullWidth
									component="span"
									variant="contained"
									endIcon={<PhotoCameraIcon />}
									size="large"
									sx={{textAlign:"center", height:"100%", minHeight:"200px", backgroundColor:standardTextColor, color:actionButtonColor, ":hover": {
											bgcolor: standardTextColor,
											color: actionButtonColor
										},}}
								>
									<b>Aggiungi foto</b>
								</Button>
							</label>
						</Grid>

						{/*<ImageList*/}
						{/*	sx={{*/}
						{/*		gridAutoFlow: "column",*/}
						{/*		gridTemplateColumns: "repeat(auto-fit, minmax(160px,1fr)) !important",*/}
						{/*		gridAutoColumns: "minmax(160px, 1fr)"*/}
						{/*	}}*/}
						{/*>*/}
						{/*	<Grid item xs={12}>*/}
						{/*		<input*/}
						{/*			type="file"*/}
						{/*			accept="image/*"*/}
						{/*			capture="environment"*/}
						{/*			multiple*/}
						{/*			style={{display:'none'}}*/}
						{/*			onChange={handleImageChange}*/}
						{/*			id="photo-input"*/}
						{/*		/>*/}
						{/*		<label htmlFor="photo-input">*/}
						{/*			<Button*/}
						{/*				fullWidth*/}
						{/*				component="span"*/}
						{/*				variant="contained"*/}
						{/*				endIcon={<PhotoCameraIcon />}*/}
						{/*				size="large"*/}
						{/*				sx={{textAlign:"center", height:"100%", minHeight:"100px", backgroundColor:standardTextColor, color:actionButtonColor}}*/}
						{/*			>*/}
						{/*				<b>Aggiungi foto</b>*/}
						{/*			</Button>*/}
						{/*		</label>*/}
						{/*	</Grid>*/}
						{/*	{selectedImages.map((image: Blob | MediaSource, index: React.Key | null | undefined) => (*/}
						{/*		<ImageListItem>*/}
						{/*			<img src={URL.createObjectURL(image)} />*/}
						{/*			/!*<ImageListItemBar title={image.thumbnail.name} />*!/*/}
						{/*		</ImageListItem>*/}
						{/*	))}*/}
						{/*</ImageList>*/}

						<Grid item xs={12}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<MobileDatePicker
									label="Data di Produzione"
									value={productionDate}
									format="DD/MM/YYYY"
									sx={{mt: 3, width: '100%', color: standardTextColor}}
									// @ts-ignore
									onChange={(newDate) => setProductionDate(newDate)}
								/>
							</LocalizationProvider>
						</Grid>

						<Grid item xs={12}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<MobileDatePicker
									label="Data di Scadenza"
									value={expirationDate}
									format="DD/MM/YYYY"
									sx={{mt: 2, mb: 1, width: '100%', color: standardTextColor}}
									// @ts-ignore
									onChange={(newDate) => setExpirationDate(newDate)}
								/>
							</LocalizationProvider>
						</Grid>

						<Grid item xs={12}>
							<FormControlLabel
								label="Surgelato"
								control={<Checkbox
									checked={isFrozen}
									onChange={handleCheckboxChange}
									inputProps={{ 'aria-label': 'controlled' }}
									sx={{
										color: standardTextColor,
										'&.Mui-checked': {
											color: standardTextColor,

										},
									}}
								/>}
								sx={{mb: 1}}
							/>
						</Grid>


						<Grid item xs={12}>
							<Button
								fullWidth
								variant="contained"
								size="large"
								endIcon={<SaveIcon/>}
								type="submit"
								sx={{backgroundColor:actionButtonColor, color:standardTextColor,
									":hover": {
										bgcolor: actionButtonColor,
										color: standardTextColor
									},
								}}
							>
								Salva
							</Button>
						</Grid>

					</Grid>
				</form>

			</Container>
		</>
	);
}

export default CreateLabelPage;
