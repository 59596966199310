const work_mode = "LOCAL";
// const local_endpoint = "http://localhost:8081/api";
// const local_endpoint = "http://192.168.8.131:8081/api";
const local_endpoint = "https://dev.matteofabris.com/api";
const remote_endpoint = "";

export const EndpointService = {

    get_endpoint: function() {
        if (work_mode === "LOCAL") {
            return local_endpoint
        }
        else if (work_mode === "REMOTE"){
            return remote_endpoint
        }
    },

    get_user_endpoint: function(append: string){
        return this.get_endpoint() + '/user'+ (append.length==0 ? '' : '/') + append
    },

    get_zone_endpoint: function(append: string){
        return this.get_endpoint() + '/zone'+ (append.length==0 ? '' : '/') + append
    },

    get_sector_endpoint: function(append: string){
        return this.get_endpoint() + '/sector'+ (append.length==0 ? '' : '/') + append
    },

    get_group_endpoint: function(append: string){
        return this.get_endpoint() + '/group'+ (append.length==0 ? '' : '/') + append
    },

    get_product_endpoint: function(append: string){
        return this.get_endpoint() + '/product'+ (append.length==0 ? '' : '/') + append
    },

    get_label_endpoint: function(append: string){
        return this.get_endpoint() + '/label'+ (append.length==0 ? '' : '/') + append
    }

};