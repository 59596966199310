import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    GlobalStyles,
    Grid,
    IconButton,
    ImageList, ImageListItem,
    Typography
} from '@mui/material';
import Container from "@mui/material/Container";
import {RequestService} from "../utils/RequestService";
import {EndpointService} from "../utils/EndpointService";
import {LocalStorageService} from "../utils/LocalStorageService";
import {actionButtonColor, grayBackgroundColor, standardTextColor} from "../style/Colors";
import {useParams} from "react-router-dom";
import LabelCreationAppBar from "../components/CreateLabelAppBar";

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {DateTimeService} from "../utils/DateTimeService";

import SaveIcon from '@mui/icons-material/Save';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import axios from "axios";
import StandardAppBar from "../components/StandardAppBar";
import {LabelService} from "../utils/LabelService";
import ViewLabelAppBar from "../components/ViewLabelAppBar";
import Modal from "@mui/material/Modal";

require('dayjs/locale/it')
dayjs.locale('it')

interface labelDataType {
    product_name: string,
    production_date: string,
    expiration_date: string,
    is_frozen: boolean,
    n_images: number
}

function ViewLabelPage() {

    let { label_id } = useParams();

    const [selectedImages, setSelectedImages] = useState<any>([]);
    const [label, setLabel] = useState<labelDataType>({product_name: "", production_date: "", expiration_date: "", is_frozen: false, n_images:0});
    const [openModal, setOpenModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isFrozen, setIsFrozen] = React.useState(false);

    const [imageUrls, setImageUrls] = useState<string[]>([]);

    useEffect(() => {

        setImageUrls([])

        RequestService.send_auth_get_request(
            EndpointService.get_label_endpoint(String(label_id))
        )
            .then(response => {
                setLabel(response.data);

                for (let i = 0 ; i < response.data.n_images ; i++) {
                    RequestService.get_image_url(
                        EndpointService.get_label_endpoint(label_id + '/image/'+i)
                    ).then(image_url => {
                        // @ts-ignore
                        // setImageUrls([image_url])
                        setImageUrls(prevState => prevState.concat([image_url]))
                    }).catch(error => {
                            // TODO improve error handling
                            console.log(error.message);
                        })
                }

            })
            .catch(error => {
                // TODO improve error handling
                if (error.response) { // status code out of the range of 2xx
                    if (error.response.status === 400){
                        // LocalStorageService.remove_access_token()
                    }
                } else if (error.request) { // The request was made but no response was received
                    console.log(error.request);
                } else {// Error on setting up the request
                    console.log('Error', error.message);
                }
            })

    }, [])

    const handleSubmit = async (e: any) => {
        e.preventDefault();


    };

    const handleImageClick = (e: any) => {
        e.preventDefault();
        setSelectedIndex(parseInt(e.target.id));
        setOpenModal(true);

    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    function getZplCode() {
        return LabelService.get_zpl_code("https://dev.matteofabris.com/label/"+label_id, dayjs(label.production_date).format('DD/MM/YYYY'), dayjs(label.expiration_date).format('DD/MM/YYYY'), label.product_name, "Azienda", "Operatore", label_id)
    }

    return (
        <>
            <GlobalStyles
                styles={{
                    body: { backgroundColor: grayBackgroundColor }
                }}
            />

            <ViewLabelAppBar title={"Riepilogo Etichetta"} showHome={true} zpl_code={getZplCode()} />

            <Container component="main" maxWidth="md">

                <Typography align="left" variant="h6" component="div" sx={{mt: 2}}>
                    Prodotto: <b>{label.product_name}</b>
                </Typography>

                <img src={LabelService.get_preview_url(
                    getZplCode()
                )} alt="label"/>

                <Grid container spacing={1} sx={{mt:1, mb:1}}>


                    {imageUrls.map((imageUrl: string, index: React.Key | null | undefined) => (
                        <Grid item xs={6}>
                            <img
                                key={index}
                                id={`${index}`}
                                src={imageUrl}
                                alt={`image ${index}`}
                                onClick={handleImageClick}
                                style={{maxWidth: '100%',
                                    maxHeight: '100%', borderRadius: '5px'}}
                            />
                        </Grid>
                    ))}

                </Grid>


            </Container>

            <Modal open={openModal} onClose={handleModalClose}>
                <Box
                    component="img"
                    src={imageUrls[selectedIndex]}
                    alt={`Image ${selectedIndex}`}
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        margin: 'auto',
                        '@media (max-width: 600px)': {
                            maxWidth: '100%',
                            maxHeight: '100%',
                        },
                    }}
                    onClick={handleModalClose}
                />
            </Modal>
        </>
    );
}

export default ViewLabelPage;
