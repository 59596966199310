import {RequestService} from "./RequestService";
import {EndpointService} from "./EndpointService";
import {LocalStorageService} from "./LocalStorageService";


export const BECheckService = {
    is_token_valid: function () {
        if (LocalStorageService.get_access_token()) {
            return RequestService.send_auth_post_request(
                EndpointService.get_user_endpoint('me'),
                {}
            )
        } else {
            throw new Error('Invalid authorization code');
        }
    },


};